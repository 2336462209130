<template>
  <div class="rootBox">
    <div class="searchOut boxStyle">
      <div class="searchParameterBox">
        <el-form :model="searchData" align="left" inline>
          <el-form-item :label="$t('dockRecords.taskName')" prop="appId">
            <el-input v-model="searchData.taskName" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('dockRecords.batchId')" prop="appId">
            <el-input v-model="searchData.batchId" size="small"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="buttonBox">
        <el-button size="small" @click="searchData = {}">{{
          $t("button.reset")
        }}</el-button>
        <el-button size="small" type="primary" @click="searchFun">{{
          $t("button.search")
        }}</el-button>
      </div>
    </div>

    <div class="contentBox boxStyle">
      <div class="operateOut">
        <p>{{ $t("dockRecords.dockRecordList") }}</p>
      </div>
      <div class="tableBox">
        <el-table
          :data="tableData"
          stripe
          header-cell-class-name="tableThStyle"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
        >
          <el-table-column
            align="center"
            prop="batchId"
            :label="$t('dockRecords.batchId')"
            min-width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="customer_code"
            :label="$t('dockRecords.customer_code')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="store_code"
            :label="$t('dockRecords.store_code')"
          >
          <template slot-scope="scope">
              {{ scope.row.store_code.split('-')[0] }}
            </template>
        </el-table-column>
          <el-table-column
            align="center"
            prop="amount"
            :label="$t('dockRecords.amount')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="success"
            :label="$t('dockRecords.success')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="failed"
            :label="$t('dockRecords.failed')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="create_time"
            :label="$t('dockRecords.create_time')"
            sortable
          >
            <template slot-scope="scope">
              {{ dateFormat(scope.row.create_time) }}
            </template>
          </el-table-column>
          <!-- <template slot-scope="scope">
              {{ dateFormat(scope.row.create_time) }}
            </template> -->
          <el-table-column
            align="center"
            prop="finish_time"
            :label="$t('dockRecords.finish_time')"
          >
            <template slot-scope="scope">
              {{ scope.row.finish_time == null ? '' : dateFormat(scope.row.finish_time) }}
            </template>
          </el-table-column>
          <!-- <template slot-scope="scope">
              {{ dateFormat(scope.row.finish_time) }}
            </template> -->
        </el-table>
      </div>

      <div class="paginationOut">
        <el-pagination
          background
          layout="prev, pager, next,jumper,sizes,total"
          popper-class="pageSizeSelect"
          :page-sizes="pagination.pageSizeOption"
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          :current-page.sync="pagination.currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/mixins";
import moment from "moment";
export default {
  mixins: [mixin],

  data() {
    return {
      searchData: {
        taskName: "",
        batchId: "",
        customer_code: "",
        store_code: "",
        amount: "",
        success: "",
        failed: "",
        create_time: "",
        finish_time: "",
      },
      batchId: "",
      taskName: "",
      tableData: [],
      batchList: [],
    };
  },
  created() {
    this.searchFun();
  },

  methods: {
    handleSizeChange: function (pageSize) {
      this.pagination.pageSize = pageSize;
      this.handleCurrentChange(this.pagination.currentPage);
    },
    handleCurrentChange: function (currentPage) {
      this.pagination.currentPage = currentPage;
      this.searchFun();
    },
    // currentChangePage(currentPage) {
    //   let from = (currentPage - 1) * this.pagination.pageSize;
    //   let to = currentPage * this.pagination.pageSize;
    //   this.tableData = this.batchList.slice(from, to);
    // },
    dateFormat: function (date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    searchFun() {
      let submitInfo = {
        start: (this.pagination.currentPage - 1) * this.pagination.pageSize,
        count: this.pagination.pageSize,
        ...this.searchData,
      };
      return new Promise((resolve, reject) => {
        this.$httpList
          .batchMsg(submitInfo)
          .then((res) => {
            this.tableData = res.data;
            this.batchNum();
            resolve(res);
          })
          .catch((res) => {
            reject(res);
          });
      });
    },
    batchNum() {
      let submitInfo = {
        start: (this.pagination.currentPage - 1) * this.pagination.pageSize,
        count: this.pagination.pageSize,
        ...this.searchData,
      };
      return new Promise((resolve, reject) => {
        this.$httpList
          .batchNum(submitInfo)
          .then((res) => {
            this.pagination.total = res.data;
            resolve(res);
          })
          .catch((res) => {
            reject(res);
          });
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>
